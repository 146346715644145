/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cross': {
    width: 8,
    height: 8,
    viewBox: '0 0 8 8',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.842.854a.5.5 0 00-.707-.707L3.995 3.29.852.147a.5.5 0 10-.707.707l3.141 3.143L.147 7.14a.5.5 0 00.706.708l3.141-3.144 3.141 3.144a.5.5 0 10.707-.708l-3.14-3.143L7.841.854z" _fill="#fff" fill-opacity=".62"/>'
  }
})
